<template>
  <div class="membership">
    <div class="container-fluid membership-banner">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-5">
            <div class="content-box">
              <h2>
                Join <br />
                Membership
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus
                quod est ea dolorem autem doloremque amet temporibus nesciunt
                voluptatibus! Facere excepturi libero
              </p>
              <!-- <div class="price-box">
                    <h2>₹4500</h2> <span class="tag">20% OFF</span>
                </div>
                <button class="btn cta-primary">Buy now</button> -->
            </div>
          </div>
          <div class="col-md-5">
            <div class="image-wrap">
              <img src="../assets/webinar-hero.svg" alt="" />
            </div>
          </div>
        </div>
        <!-- <div class="row justify-content-end">
            <div class="col-md-6">
              <div class="promocode-box">
                <p>Promo Code: BIG20</p>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="button-addon2">
                    <button class="btn btn-outline-secondary" type="button" id="button-addon2">Apply</button>
                </div>
              </div>
            </div>
          </div> -->
      </div>
    </div>
    <div class="pricing-wrapper">
      <div class="container">
        <div class="card-wrap">
          <div class="price-card type-1">
            <div class="_head">
              <h5>Premium</h5>
              <circular-loader
                v-if="membershipLoader == true"
              ></circular-loader>
              <h5 v-if="membershipLoader == false">
                ₹ {{ premiumAmount }}
                <span v-if="premiumMembershipDetails.offer > 0"
                  >({{ premiumMembershipDetails.offer }}% off)</span
                >
              </h5>
              <span
                v-if="
                  premiumMembershipDetails.offer > 0 &&
                    // eslint-disable-next-line prettier/prettier
                    membershipLoader == false"
                ><s>₹ {{ premiumMembershipDetails.price }}</s></span
              >
            </div>
            <div class="_body">
              <div v-if="premiumFeaturesCount > 0">
                <ul
                  class="list-unstyled"
                  v-for="(premiumFeature,
                  premiumFeatureIndex) in premiumFeatures"
                  :key="premiumFeatureIndex"
                  :value="premiumFeature.id"
                >
                  <li>{{ premiumFeature.feature }}</li>
                </ul>
              </div>
              <router-link
                :to="{ name: 'membershipCheckout', query: { type: 'premium' } }"
                class="btn cta-primary"
                >Buy now
              </router-link>
            </div>
          </div>
          <div class="price-card type-2">
            <div class="_head">
              <h5>Club</h5>
              <circular-loader
                v-if="membershipLoader == true"
              ></circular-loader>
              <h5 v-if="membershipLoader == false">
                ₹ {{ clubAmount }}
                <span v-if="clubMembershipDetails.offer > 0"
                  >({{ clubMembershipDetails.offer }}% off)</span
                >
              </h5>
              <span
                v-if="
                  clubMembershipDetails.offer > 0 &&
                    // eslint-disable-next-line prettier/prettier
                    membershipLoader == false"
                ><s>₹ {{ clubMembershipDetails.price }}</s></span
              >
            </div>
            <div class="_body">
              <div v-if="clubFeaturesCount > 0">
                <ul
                  class="list-unstyled"
                  v-for="(clubFeature, clubFeatureIndex) in clubFeatures"
                  :key="clubFeatureIndex"
                  :value="clubFeature.id"
                >
                  <li>{{ clubFeature.feature }}</li>
                </ul>
              </div>
              <router-link
                :to="{ name: 'membershipCheckout', query: { type: 'club' } }"
                class="btn cta-primary"
                >Buy now
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="highlight-wrapper">
      <div class="container">
        <h3>Description</h3>
        <p v-html="membershipContent.contents"></p>
        <!-- <div class="row">
          <div class="col-md-6">
            <div class="highlight-box">
              <div class="icon-box">
                <img src="../assets/spec-1.svg" alt="" />
              </div>
              <div class="content">
                <h5>weekly 3 days</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Nesciunt, non.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="highlight-box">
              <div class="icon-box">
                <img src="../assets/spec-2.svg" alt="" />
              </div>
              <div class="content">
                <h5>weekly 3 days</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Nesciunt, non.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="highlight-box">
              <div class="icon-box">
                <img src="../assets/spec-3.svg" alt="" />
              </div>
              <div class="content">
                <h5>weekly 3 days</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Nesciunt, non.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="highlight-box">
              <div class="icon-box">
                <img src="../assets/spec-4.svg" alt="" />
              </div>
              <div class="content">
                <h5>weekly 3 days</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Nesciunt, non.
                </p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="faq-wrapper">
      <div class="container">
        <h4>frequently asked questions</h4>

        <div class="accordion" id="accordionExample">
          <div
            class="accordion-item"
            v-for="(faq, faqIndex) in faqs"
            :key="faqIndex"
          >
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                :class="faqIndex == 0 ? '' : 'collapsed'"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + faq.id"
                aria-expanded="true"
                :aria-controls="'collapse' + faq.id"
              >
                {{ faq.question }}
              </button>
            </h2>
            <div
              :id="'collapse' + faq.id"
              class="accordion-collapse collapse "
              :class="faqIndex == 0 ? 'show' : ''"
              :aria-labelledby="'heading' + faq.id"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {{ faq.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cta-wrapper">
      <div class="container">
        <h5>
          Limited seats avilable <br />
          get your subscription now
        </h5>
        <button class="btn cta-primary">Buy now</button>
      </div>
    </div>
  </div>
</template>

<script>
import MembershipService from "@/services/MembershipService";
import CircularLoader from "@/components/loaders/CircularLoader";
import errorLog from "@/errorLog";
export default {
  name: "membership",
  components: {
    CircularLoader
  },
  created: function() {
    this.getFaq();
    this.getMembershipContents();
    this.getMembershipDetails();
  },
  data() {
    return {
      membershipLoader: true,
      faqs: [],
      membershipContent: "",
      clubMembershipDetails: "",
      premiumMembershipDetails: "",
      premiumFeaturesCount: "",
      clubFeaturesCount: "",
      clubFeatures: [],
      premiumFeatures: []
    };
  },
  computed: {
    premiumAmount() {
      let premiumOffer = this.premiumMembershipDetails.offer;
      let reduction =
        this.premiumMembershipDetails.price * (premiumOffer / 100);
      return (
        parseFloat(this.premiumMembershipDetails.price) - parseFloat(reduction)
      );
    },
    clubAmount() {
      let clubOffer = this.clubMembershipDetails.offer;
      let reduction = this.clubMembershipDetails.price * (clubOffer / 100);
      return (
        parseFloat(this.clubMembershipDetails.price) - parseFloat(reduction)
      );
    }
  },
  methods: {
    getMembershipDetails() {
      this.membershipLoader = true;
      MembershipService.getMembershipDetail()
        .then(result => {
          this.membershipLoader = false;
          this.premiumMembershipDetails = result.data.premium_membership;
          this.premiumFeaturesCount =
            result.data.premium_membership.features.length;
          this.premiumFeatures = result.data.premium_membership.features;
          this.clubMembershipDetails = result.data.club_membership;
          this.clubFeaturesCount = result.data.club_membership.features.length;
          this.clubFeatures = result.data.club_membership.features;
          if (this.premiumMembershipDetails.price == null) {
            this.premiumMembershipDetails.price = 0;
          }
          if (this.clubMembershipDetails.price == null) {
            this.clubMembershipDetails.price = 0;
          }
        })
        .catch(error => {
          this.membershipLoader = false;
          errorLog.log(error);
        });
    },
    getFaq() {
      this.isFaqsLoader = true;
      MembershipService.getFaqs()
        .then(result => {
          this.isFaqsLoader = false;
          this.faqs = result.data.data;
        })
        .catch(error => {
          this.isFaqsLoader = false;
          errorLog.log(error);
        });
    },
    getMembershipContents() {
      MembershipService.getMembershipContent()
        .then(result => {
          this.membershipContent = result.data.membership;
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/membership.scss";
</style>
